// Help Menu
export const imprintLink = 'https://www.engelvoelkers.com/en-de/ev-tech-platform-products/imprint/';
export const supportLink =
  'https://help.engelvoelkers.com/en/support/solutions/articles/76000047735-overview-of-all-functions';

export const featureRequestLink = 'https://upvoty.engelvoelkers.com/b/kyc/';
export const knowledgeBaseLink = 'https://help.engelvoelkers.com/support/solutions/53000021498';
export const aboutThisToolLink = 'https://newsboard.engelvoelkers.com/kyc/';

//Popup Disclaimer German
export const moreNewsboardLink = 'https://newsboard.engelvoelkers.com/de/legal-compliance/geldwaeschepraevention/';
export const moreAMLPreventionLink = 'https://drive.google.com/file/d/1AIOFNAp2XKYZ7l7poCzmZMuqsE0EOVqI/view';
